import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { UserBalance } from 'src/app/models/models';
import { User } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';

@Component({
	selector: 'app-list-provider',
	templateUrl: './list-provider.component.html',
	styleUrls: ['./list-provider.component.css']
})
export class ListProviderComponent extends BaseComponent implements OnInit
{
	file:File = null;
	show_import:boolean = false;
	user_search:SearchObject<UserBalance> = this.getEmptySearch();
	user_list:UserBalance[] = [];

	ngOnInit()
	{
		this.path = '/list-provider';

		this.subs.sink = this.route.queryParamMap
		.pipe
		(
			mergeMap((queryParamMap)=>
			{
				let fields =
				[
					"id","default_billing_address_id","organization_id",
					"default_shipping_address_id", "price_type_id","store_id","credit_limit","name",
					"username","contact", "business_name", "email","phone","type","password",
					"image_id","created_by_user_id","updated_by_user_id", "created","updated"
				];

				let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
				this.user_search = this.getSearch(queryParamMap, fields, extra_keys );
				this.setTitle('Proveedores');
				this.user_search.search_extra['user_permission.is_provider'] = 1;
				this.user_search.eq.type = 'USER';
				this.current_page = this.user_search.page;
				this.is_loading = true;
				return this.rest.user.search(this.user_search)
			})
		)
		.subscribe((responses)=>
		{
			this.user_list = responses.data;
			this.setPages( this.user_search.page, responses.total );
			this.is_loading = false;
		},(error)=>this.showError(error));
	}
	searchClient(search_object:SearchObject<User>)
	{
		search_object.csv.id = [];
		search_object.eq.type = 'CLIENT';
		this.search(search_object);
	}

	onSearch(evt:Event)
	{
		this.searchNoForceReload(this.user_search);
	}

	print()
  	{
		this.rest.hideMenu();
		setTimeout(() => window.print(), 500);
  	}

	  export()
	  {
		const rows = this.user_list.map((r) => ({
		  'Proveedor': r.name,
		  'Balance': r.balance,
		  'Actualizado': r.updated
		}));
	
		const headers = ['Proveedor', 'Balance', 'Actualizado'];
		ExcelUtils.array2xlsx(rows, 'Proveedores.xlsx', headers);
	  }
}
