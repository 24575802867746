<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-10 py-2">
			<h1>Lista de Facturas</h1>
		</div>
		<app-pagination [path]="'/list-sat-factura'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
		<div class="card my-3 py-3 px-1 px-lg-3">
			<app-loading [is_loading]="is_loading"></app-loading>
			<div class="row border-bottom">
				<div class="table-responsive">
					<table class="table">
						<thead class="fw-bold">
							<tr>
								<th class="text-start">ID</th>
								<th class="text-center">Detalle</th>
								<th class="text-center">UUID</th>
								<th class="text-center">Estatus</th>
								<th class="text-center">Estatus CFDI</th>
								<th class="text-end">Acciones</th>
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let sat_factura of sat_factura_info_list">
								<tr>
									<td class="text-start">{{sat_factura.id}}</td>
									<td class="text-center">
										<a *ngIf="sat_factura.link.length" [routerLink]="sat_factura.link">
											{{sat_factura.name_type}}
										</a>
									</td>
									<td class="text-center">{{sat_factura.uuid}}</td>
									<td class="text-center text-danger" *ngIf="sat_factura.system_cancelled_timestamp">
										{{sat_factura.system_status}}<br>
										{{sat_factura.system_cancelled_timestamp | date: 'dd/MM/yyyy HH:mm:ss'}}
									</td>
									<td class="text-center text-success" *ngIf="sat_factura.system_cancelled_timestamp == null">
										{{sat_factura.system_status}}
									</td>
									<td class="text-center text-success" *ngIf="sat_factura.solicitud_cancelacion_sat_timestamp">
										Cancelación solicitada: <br>
										{{sat_factura.solicitud_cancelacion_sat_timestamp | date: 'dd/MM/yyyy HH:mm:ss'}}
									</td>
									<td class="text-center" *ngIf="sat_factura.solicitud_cancelacion_sat_timestamp == null">
										{{sat_factura.sat_cancelled}}
									</td>
									<td class="text-end">
										<a class="mx-1 btn btn-primary" [href]="getPdfUrl(sat_factura.order_id)">
											<abbr title="Descargar PDF">
												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512">
													<path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"/>
												</svg>
											</abbr>
										</a>
										<a class="mx-1 btn btn-primary" *ngIf="sat_factura?.xml_attachment_id" [href]="rest.getFilePath(sat_factura.xml_attachment_id,true)">
											<abbr title="Descargar XML">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-code-fill" viewBox="0 0 16 16">
													<path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.646 7.646a.5.5 0 1 1 .708.708L5.707 10l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 10 8.646 8.354a.5.5 0 1 1 .708-.708"/>
												</svg>
											</abbr>
										</a>
										<a class="mx-1 btn btn-primary" *ngIf="sat_factura?.pdf_attachment_id" (click)="show_reenviar_factura=true">
											<abbr title="Reenviar Factura">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-code-fill" viewBox="0 0 24 24">
													<path fill="currentColor" d="M3 23q-.825 0-1.412-.587T1 21V3q0-.825.588-1.412T3 1h18q.825 0 1.413.588T23 3v18q0 .825-.587 1.413T21 23zm3.85-4H17q.825 0 1.413-.587T19 17v-3q0-.425-.288-.712T18 13t-.712.288T17 14v3H6.85l.85-.85q.3-.3.288-.7t-.288-.7q-.3-.3-.712-.312t-.713.287L3.7 17.3q-.15.15-.213.325T3.426 18t.063.375t.212.325l2.575 2.575q.3.3.713.288t.712-.313q.275-.3.288-.7t-.288-.7zm10.3-12l-.85.85q-.3.3-.288.7t.288.7q.3.3.712.312t.713-.287L20.3 6.7q.15-.15.213-.325T20.575 6t-.062-.375T20.3 5.3l-2.575-2.575q-.3-.3-.712-.288t-.713.313q-.275.3-.288.7t.288.7l.85.85H7q-.825 0-1.412.588T5 7v3q0 .425.288.713T6 11t.713-.288T7 10V7z"/>
												</svg>
											</abbr>
										</a>
									</td>
								</tr>
							</ng-container>
						</tbody>

					</table>

				</div>
			</div>
		</div>
	</div>

	<app-pagination [path]="'/list-sat-factura'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

<app-modal [(show)]="show_reenviar_factura" [closable]="false">
	<div class="container-fluid" *ngIf="sat_factura_info_list">
		<h2>Reenviar Factura por correo</h2>
		<form name="send_factura_form" (submit)="resendFactura($event)" ngNativeValidate>
			<app-loading [is_loading]="is_loading"></app-loading>
			<div class="row">
				<div class="col-12 col-md-6">
					<label>Nombre</label>
					<input type="text" name="name" [(ngModel)]="reenviar_factura_name" class="form-control">
				</div>
				<div class="col-12 col-md-6">
					<label>Correo Electrónico</label>
					<input type="email" name="factura_email" [(ngModel)]="reenviar_factura_email" class="form-control" required>
				</div>
			</div>
			<div class="row py-3">
				<div class="col-6">
					<button type="button" class="btn btn-primary w-100" (click)="show_reenviar_factura=false" [disabled]="is_loading">Cancelar</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary w-100">Enviar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>
