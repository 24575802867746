import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { Address, Billing_Data } from 'src/app/models/RestModels';
import { CustomOrderInfo, ListOrderComponent } from '../list-order/list-order.component';

@Component
({
	selector: 'app-factura-multiple',
	templateUrl: './factura-multiple.component.html',
	styleUrls: ['./factura-multiple.component.css']
})
export class FacturaMultipleComponent extends ListOrderComponent
{
	select_all:boolean = false;
	first_order_info: CustomOrderInfo | null = null;
	billing_data_list: Billing_Data[] = [];
	show_facturar_modal: boolean = false;
	address_list: Address[] = [];


	lugar_de_expedicion: string='';
	sat_razon_social: string='';
	sat_uso_cfdi: string='';
	sat_receptor_email: string='';
	sat_receptor_rfc: string='';
	sat_regimen_fiscal_receptor: string='';
	sat_domicilio_fiscal_receptor: string='';
	sat_regimen_capital: string='';
	sat_condiciones_de_pago: string='';
	sat_metodo_de_pago: string='';
	sat_forma_de_pago: string = '';
	sat_serie: string='';
    sat_nota:string = '';

	billing_data_id: number | null;
	transaccion: string = '';

	ngOnInit(): void
	{
		this.subs.sink = this.route.params.subscribe
		((response)=>
		{
			this.transaccion = 'FM-'+Date.now();
			console.log('Transaccion '+this.transaccion);
		});

		super.ngOnInit();
	}

	onSelectAllCheckbox(event:any)
	{
		this.select_all = event.target.checked;

		for(let i=0;i<this.order_info_list.length;i++)
		{
			this.order_info_list[i].selected = this.select_all;
		}
	}

	onSelectOrder(evt:any,order_info:CustomOrderInfo)
	{
		order_info.selected = !order_info.selected;
		this.select_all = this.order_info_list.every(oi=>oi.selected);
	}

	onBillingDataChange(evt:any)
	{
		this.first_order_info.order.billing_data_id = evt.target.value;
	}

	showFacturarModal(evt:Event)
	{
		this.is_loading = true;

		console.log('FOOOO',this.client);

		this.sat_condiciones_de_pago = '';
		this.sat_metodo_de_pago = 'PUE';
		this.sat_forma_de_pago = '01';


		this.subs.sink = forkJoin
		({
			billing_data: this.rest.billing_data.search({limit:999999}),
			address: this.client
				? this.rest.address.search({eq:{user_id:this.client.id},limit:999999})
				: of({ data:[], total:0 })
		})
		.subscribe((response)=>
		{
			let orders_to_bill = this.order_info_list.filter(oi=>oi.selected).map(oi=>oi);

			if(orders_to_bill.length == 0)
			{
				this.showError('No se han seleccionado órdenes para facturar');
				return;
			}

			this.first_order_info = orders_to_bill[0];
			this.sat_serie = this.first_order_info.order.sat_serie;
			this.billing_data_list = response.billing_data.data;
			this.address_list = response.address.data;
			this.show_facturar_modal = true;
			this.is_loading= false;
		});
	}

	save(evt:Event):void
	{
		evt.preventDefault();
		evt.stopPropagation();
		this.is_loading = true;

		let orders_to_bill = this.order_info_list.filter(oi=>oi.selected).map(oi=>oi);

		if(orders_to_bill.length == 0)
		{
			this.showError('No se han seleccionado órdenes para facturar');
			return;
		}

		let order_ids = this.order_info_list
			.filter(oi=>oi.selected)
			.sort((a,b)=>a.order.id - b.order.id)
			.map(oi=>oi.order.id).join(',');

		let f_request = {
			order_ids,
			billing_data_id: this.billing_data_id,
			lugar_de_expedicion: this.lugar_de_expedicion,
			sat_razon_social: this.sat_razon_social,
			sat_uso_cfdi: this.sat_uso_cfdi,
			sat_receptor_email: this.sat_receptor_email,
			sat_receptor_rfc:this.sat_receptor_rfc,
			sat_regimen_fiscal_receptor: this.sat_regimen_fiscal_receptor,
			sat_domicilio_fiscal_receptor: this.sat_domicilio_fiscal_receptor,
			sat_regimen_capital: this.sat_regimen_capital,
			sat_forma_de_pago: this.sat_forma_de_pago,
			sat_condiciones_de_pago: this.sat_condiciones_de_pago,
			sat_metodo_de_pago:this.sat_metodo_de_pago,
			sat_serie: this.sat_serie,
			sat_nota: this.sat_nota,
			transaccion: this.transaccion
		};

		this.first_order_info = orders_to_bill[0];

		this.subs.sink = this.rest.updatePath('facturaMultiple', f_request)
		.subscribe
		({
			next:(response)=>
			{
				this.showSuccess('Facturado correctamente');
			},
			error:(error)=>
			{
				this.showError(error);
			}
		})
	}

	onChangeAddress(value:any)
	{
		console.log( value );
		let address = this.address_list.find(a=>a.name == value);

		if( address )
		{
			this.sat_razon_social = address.name;
			this.sat_uso_cfdi = address.sat_uso_cfdi;
			this.sat_receptor_email = address.email;
			this.sat_receptor_rfc = address.rfc;
			this.sat_domicilio_fiscal_receptor = address.zipcode;
			this.sat_regimen_fiscal_receptor = address.sat_regimen_fiscal;
			this.sat_regimen_capital = address.sat_regimen_capital;
		}
	}
}
