<div class="container-fluid">
	<h1 class="text-capitalize mt-3">{{user.id ? 'Actualizar':'Agregar'}} Usuario</h1>
	<form ngNativeValidate (submit)="save()" name="save-user-form">
		<div class="row mt-3">
			<div class="col-12">
				<div class="card p-3">
					<div class="row p-3 align-items-top">
						<div class="col-4 col-md-2 col-lg-1 control-group d-flex flex-column justify-content-center align-items-center">
							<label class="">Imagen</label>
							<div class="w-50">
								<app-image-uploader [(image)]="user.image_id"></app-image-uploader>
							</div>
						</div>
						<div class="col-9 col-md-10 col-lg-11">
							<div class="row">
								<div class="col-12 col-md-4">
									<label class="">Nombre</label>
									<input type="text" name="name" [(ngModel)]="user.name" class="form-control" required>
								</div>
								<!--div class="col-6 col-md-4">
									<label class="">Contacto</label>
									<input type="text" name="contact" [(ngModel)]="user.contact" class="form-control">
								</div-->
								<div class="col-12 col-md-4">
									<label class="">Usuario</label>
									<input type="text" name="email" [(ngModel)]="user.email" class="form-control" required>
								</div>
								<div class="col-12 col-md-4">
									<label class="">Teléfono</label>
									<input type="
									text" name="phone" [(ngModel)]="user.phone" class="form-control">
								</div>
								<div class="col-12 col-md-4">
									<label>Contraseña</label>
									<div class="input-group">
										<input [type]="password_type" type="password" name="password" [(ngModel)]="user.password" class="form-control" required>
										<div class="input-group-text">
											<input class="form-check-input mt-0" type="checkbox" value="" aria-label="Mostrar Password" (click)="togglePasswordType($event)">
										</div>
									</div>
								</div>
								<div class="col-12 col-md-4 from-group" *ngIf="rest.current_permission.add_user">
									<label class="">Sucursal</label>
									<select name="store_id" [(ngModel)]="user.store_id" class="form-control" required>
										<option [ngValue]="null">Seleccionar</option>
										<option *ngFor="let store of store_list" [ngValue]="store.id">
											{{store.name | titlecase}}
										</option>
									</select>
								</div>
								<div class="col-12 col-md-4 text-end">
									<label>&nbsp;</label>
									<div>
										<button type="submit" [disabled]="is_loading" class="btn btn-primary w-100">
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
											Guardar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ng-container *ngIf="rest.current_permission.add_user">
				<div class="col-12 col-md-6 col-lg-3 mt-3">
					<h4>Gastos</h4>
					<div class="card p-3">
						<div class="row">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="all_bills" (click)="toggleAll('all_bills',['pay_bills','add_bills','approve_bill_payments'])"
									class="form-check-input" [checked]="user_permission.pay_bills == 1 && user_permission.add_bills == 1 && user_permission.approve_bill_payments== 1" >
									Aplicar todo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="pay_bills" (click)="toggleValue('pay_bills')" class="form-check-input" [checked]="user_permission.pay_bills == 1">
									Pagar Gastos
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_bills" (click)="toggleValue('add_bills')" class="form-check-input" [checked]="user_permission.add_bills == 1">
									Agregar Gastos
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="approve_bill_payments" (click)="toggleValue('approve_bill_payments')" class="form-check-input" [checked]="user_permission.approve_bill_payments== 1">
									Aprobar Gastos
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mt-3">
					<h4>Precios</h4>
					<div class="card p-3">
						<div class="row">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="all_bills" (click)="toggleAll('all_prices',['store_prices','global_prices','price_types', 'change_client_prices'])"
									class="form-check-input" [checked]="user_permission.store_prices == 1 && user_permission.global_prices == 1 && user_permission.price_types== 1 && user_permission.change_client_prices ==1" >
									Aplicar todo
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="store_prices" (click)="toggleValue('store_prices')" class="form-check-input" [checked]="user_permission.store_prices ==1">
									Cambiar Precios de Sucursal
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="global_prices" (click)="toggleValue('global_prices')" class="form-check-input" [checked]="user_permission.global_prices ==1">
									Editar Precios Globales
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="price_types" (click)="toggleValue('price_types')" class="form-check-input" [checked]="user_permission.price_types ==1">
									Editar Tipos De precios
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="change_client_prices" (click)="toggleValue('change_client_prices')" class="form-check-input" [checked]="user_permission.change_client_prices ==1">
									Seleccionar lista de precios en Punto de venta
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mt-3">
					<h4>Inventarios</h4>
					<div class="card p-3">
						<div class="row">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="all_stock" (click)="toggleAll('all_stock',['add_items','view_stock','add_stock','global_add_stock', 'stocktake'])"
									class="form-check-input" [checked]="user_permission.add_items==1 && user_permission.view_stock==1 && user_permission.add_stock==1 && user_permission.global_add_stock==1 && user_permission.stocktake==1" >
									Aplicar todo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_items" (click)="toggleValue('add_items')" class="form-check-input" [checked]="user_permission.add_items == 1">
									Agregar Artículos y Categorias
								</label>
							</div>


							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="view_stock" (click)="toggleValue('view_stock')" class="form-check-input" [checked]="user_permission.view_stock== 1">
									Ver Inventarios
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_stock" (click)="toggleValue('add_stock')" class="form-check-input" [checked]="user_permission.add_stock== 1">
									Agregar/Editar Inventarios
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="global_add_stock" (click)="toggleValue('global_add_stock')" class="form-check-input" [checked]="user_permission.global_add_stock== 1">
									Agregar/Editar Inventarios Globales
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="stocktake" (click)="toggleValue('stocktake')" class="form-check-input" [checked]="user_permission.stocktake == 1">
									Inventariar
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mt-3">
					<h4>Envíos</h4>
					<div class="card p-3">
						<div class="row">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="all_shipping" (click)="toggleAll('all_shipping',['send_shipping','global_send_shipping','receive_shipping','global_receive_shipping','global_send_shipping'])"
									class="form-check-input" [checked]="user_permission.send_shipping==1 && user_permission.global_send_shipping==1 && user_permission.receive_shipping==1 && user_permission.global_receive_shipping==1" >
									Aplicar todo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="send_shipping" (click)="toggleValue('send_shipping')" class="form-check-input" [checked]="user_permission.send_shipping == 1">
									Crear Envíos
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="global_send_shipping" (click)="toggleValue('global_send_shipping')" class="form-check-input" [checked]="user_permission.global_send_shipping ==1 ">
									Crear Envíos Globales
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="receive_shipping" (click)="toggleValue('receive_shipping')" class="form-check-input" [checked]="user_permission.receive_shipping ==1 ">
									Recibir Envíos
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="global_receive_shipping" (click)="toggleValue('global_receive_shipping')" class="form-check-input" [checked]="user_permission.global_receive_shipping ==1 ">
									Recibir Envíos Globales
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2" *ngIf="user_permission.receive_shipping == 1 || user_permission.global_receive_shipping == 1">
								<label class="form-check-label">
									Tipo de recepción
									<select name="receive_shipping_type" [(ngModel)]="user_permission.shipping_receive_type" class="form-control">
										<option value="CAPTURE_QTY">Capturar cantidad</option>
										<option value="VALIDATE">Validar</option>
									</select>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-3 mt-3">
					<h4>Administración</h4>
					<div class="card p-3">
						<div class="row">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="all_about_user" (click)="toggleAll('all_about_user',['add_user','add_asistance','view_asistance','add_payroll','view_payroll','add_form','view_responses','preferences','reports','add_commandas','currency_rates','edit_billing_data','facturar_orden', 'factura_global', 'cancelar_factura'])"
									class="form-check-input" [checked]="user_permission.add_user==1 && user_permission.add_asistance && user_permission.view_asistance && user_permission.add_payroll && user_permission.view_payroll && user_permission.view_asistance && user_permission.add_form && user_permission.view_responses && user_permission.add_form && user_permission.preferences==1 && user_permission.reports==1 && user_permission.add_commandas==1 && user_permission.currency_rates==1 && user_permission.edit_billing_data==1" >
									Aplicar todo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_user" (click)="toggleValue('add_user')" class="form-check-input" [checked]="user_permission.add_user ==1">
									Agregar Usuarios y Sucursales
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_asistance" (click)="toggleValue('add_asistance')" class="form-check-input" [checked]="user_permission.add_asistance ==1">
									Registrar Asistencia
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="view_asistance" (click)="toggleValue('view_asistance')" class="form-check-input" [checked]="user_permission.view_asistance ==1">
									Ver Asistencia
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_payroll" (click)="toggleValue('add_payroll')" class="form-check-input" [checked]="user_permission.add_payroll ==1">
									Registrar Nóminas
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="view_payroll" (click)="toggleValue('view_payroll')" class="form-check-input" [checked]="user_permission.view_payroll ==1">
									Ver Nóminas
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_form" (click)="toggleValue('add_form')" class="form-check-input" [checked]="user_permission.add_form ==1">
									Agregar Formularios
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="view_responses" (click)="toggleValue('view_responses')" class="form-check-input" [checked]="user_permission.view_responses ==1">
									Ver Respuestas
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="preferences" (click)="toggleValue('preferences')" class="form-check-input" [checked]="user_permission.preferences ==1">
									Preferencias
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="reports" (click)="toggleValue('reports')" class="form-check-input" [checked]="user_permission.reports==1">
									Ver Reportes
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_commandas" (click)="toggleValue('add_commandas')" class="form-check-input" [checked]="user_permission.add_commandas == 1">
									Agregar comandas
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="currency_rates" (click)="toggleValue('currency_rates')" class="form-check-input" [checked]="user_permission.currency_rates== 1">
									Tipos de cambio
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="edit_billing_data" (click)="toggleValue('edit_billing_data')" class="form-check-input" [checked]="user_permission.edit_billing_data== 1">
									Datos De facturación
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="facturar_orden" (click)="toggleValue('facturar_orden')" class="form-check-input" [checked]="user_permission.facturar_orden">
									Facturar Ordenes
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="factura_global" (click)="toggleValue('factura_global')" class="form-check-input" [checked]="user_permission.factura_global">
									Facturacion por periodo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="cancelar_factura" (click)="toggleValue('cancelar_factura')" class="form-check-input" [checked]="user_permission.cancelar_factura">
									Cancelar Factura
								</label>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 col-md-6 col-lg-3 mt-3">
					<h4>Requisición y Ordenes de compra</h4>
					<div class="card p-3">
						<div class="row">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="all_requisitions" (click)="toggleAll('all_requisitions',['add_requisition','global_requisition','approve_requisition', 'production','purchases','global_purchases','add_purchases','quotes','add_providers'])"
									class="form-check-input" [checked]="user_permission.add_requisition==1 && user_permission.global_requisition==1 && user_permission.approve_requisition==1 && user_permission.purchases==1 && user_permission.global_purchases==1 && user_permission.add_purchases==1 && user_permission.quotes==1 && user_permission.add_providers==1 && user_permission.global_requisition && user_permission.production">
									Aplicar todo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_requisition" (click)="toggleValue('add_requisition')" class="form-check-input" [checked]="user_permission.add_requisition ==1">
									Requisiciones
								</label>
							</div>


							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="global_requisition" (click)="toggleValue('global_requisition')" class="form-check-input" [checked]="user_permission.global_requisition ==1">
									Requisiciones Globales
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="approve_requisition" (click)="toggleValue('approve_requisition')" class="form-check-input" [checked]="user_permission.approve_requisition ==1">
									Aprobar Requisiciones
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="production" (click)="toggleValue('production')" class="form-check-input" [checked]="user_permission.production==1">
									Agregar Producción
								</label>
							</div>


							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="purchases" (click)="toggleValue('purchases')" class="form-check-input" [checked]="user_permission.purchases==1">
									Consultar Ordenes de compra
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="global_purchases" (click)="toggleValue('global_purchases')" class="form-check-input" [checked]="user_permission.global_purchases==1">
									Ordenes De Compra Globales
								</label>
							</div>


							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_purchases" (click)="toggleValue('add_purchases')" class="form-check-input" [checked]="user_permission.add_purchases==1">
									Agregar Ordenes de Compra
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="quotes" (click)="toggleValue('quotes')" class="form-check-input" [checked]="user_permission.quotes==1">
									Cotizaciones
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_providers" (click)="toggleValue('add_providers')" class="form-check-input" [checked]="user_permission.add_providers==1">
									Agregar Proveedores
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mt-3">
					<h4>Ordenes</h4>
					<div class="card p-3">
						<div class="row">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="all_pos" (click)="toggleAll('all_orders',['advanced_order_search','fullfill_orders','cancel_closed_orders','show_tables','order_delivery','global_order_delivery','view_commandas'])"
									class="form-check-input" [checked]="user_permission.advanced_order_search==1 && user_permission.fullfill_orders==1 && user_permission.cancel_closed_orders==1 && user_permission.show_tables==1 && user_permission.order_delivery==1 && user_permission.global_order_delivery==1 && user_permission.view_commandas==1">
									Aplicar todo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="advanced_order_search" (click)="toggleValue('advanced_order_search')" class="form-check-input" [checked]="user_permission.advanced_order_search ==1">
									Búsqueda Avanzada de ordenes de venta
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="fullfill_orders" (click)="toggleValue('fullfill_orders')" class="form-check-input" [checked]="user_permission.fullfill_orders ==1">
									Surtir Ordenes
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="cancel_closed_orders" (click)="toggleValue('cancel_closed_orders')" class="form-check-input" [checked]="user_permission.cancel_closed_orders ==1">
									Cancelar Ordenes Cerradas
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="show_tables" (click)="toggleValue('show_tables')" class="form-check-input" [checked]="user_permission.show_tables ==1">
									Mostrar Mesas
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="order_delivery" (click)="toggleValue('order_delivery')" class="form-check-input" [checked]="user_permission.order_delivery ==1">
									Entrega de Ordenes
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="global_order_delivery" (click)="toggleValue('global_order_delivery')" class="form-check-input" [checked]="user_permission.global_order_delivery ==1">
									Entrega de Ordenes Globales
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="view_commandas" (click)="toggleValue('view_commandas')" class="form-check-input" [checked]="user_permission.view_commandas ==1">
									Ver Comandas
								</label>
							</div>

						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-3 mt-3">
					<h4>Punto de venta</h4>
					<div class="card p-3">
						<div class="row">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="all_pos" (click)="toggleAll('all_pos',['pos','cancel_ordered_item','open_cashier_box_anytime','print_pre_receipt','global_pos','pv_returns','add_payments','discounts', 'add_credit_sales','return_money'])"
									class="form-check-input" [checked]="user_permission.pos==1 && user_permission.cancel_ordered_item==1 && user_permission.open_cashier_box_anytime==1 && user_permission.print_pre_receipt==1 && user_permission.global_pos==1 && user_permission.pv_returns==1 && user_permission.add_payments==1 && user_permission.discounts==1 && user_permission.add_credit_sales ==1 && user_permission.return_money">
									Aplicar todo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="pos" (click)="toggleValue('pos')" class="form-check-input" [checked]="user_permission.pos ==1">
									Punto de venta
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="cancel_ordered_item" (click)="toggleValue('cancel_ordered_item')" class="form-check-input" [checked]="user_permission.cancel_ordered_item==1">
									Cancelar Articulos en preparacion
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="open_cashier_box_anytime" (click)="toggleValue('open_cashier_box_anytime')" class="form-check-input" [checked]="user_permission.open_cashier_box_anytime==1">
									Abrir Caja En cualquier Momento
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="print_pre_receipt" (click)="toggleValue('print_pre_receipt')" class="form-check-input" [checked]="user_permission.print_pre_receipt ==1">
									Imprimir Pre-Recibo
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="global_pos" (click)="toggleValue('global_pos')" class="form-check-input" [checked]="user_permission.global_pos ==1">
									Punto de venta Global
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="pv_returns" (click)="toggleValue('pv_returns')" class="form-check-input" [checked]="user_permission.pv_returns ==1">
									Devoluciones Punto de venta
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_payments" (click)="toggleValue('add_payments')" class="form-check-input" [checked]="user_permission.add_payments ==1">
									Recibir pagos de clientes
								</label>
							</div>
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="return_money" (click)="toggleValue('return_money')" class="form-check-input" [checked]="user_permission.return_money ==1">
									Regresar dinero en devoluciones
								</label>
							</div>


							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="discounts" (click)="toggleValue('discounts')" class="form-check-input" [checked]="user_permission.discounts ==1">
									Aplicar Descuentos
								</label>
							</div>

							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" name="add_credit_sales" (click)="toggleValue('add_credit_sales')" class="form-check-input" [checked]="user_permission.add_credit_sales ==1">
									Ventas a credito
								</label>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</form>
</div>
