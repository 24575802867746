<div class="container-fluid">
	<div class="row">
		<div class="col-10"	>
			<h1 class="my-3">Cortes de Sucursal</h1>
		</div>
		<div class="col-2 text-end">
			<a routerLink="/create-sales-report" class="btn btn-secondary my-3">Agregar</a>
		</div>
	</div>

	<div class="card p-3 mb-3">
		<div class="row">
			<div class="col-6 form-group">
				<label>Sucursal</label>
				<select [(ngModel)]="sales_store_report_search.eq.store_id" class="form-control d-print-none">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let store of store_list" [value]="store.id" [selected]="sales_store_report_search.search_extra.store_id == store.id">
						{{store.name | titlecase}}
					</option>
				</select>
			</div>
			<div class="col-6 form-group">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary" (click)="search(sales_store_report_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						Buscar
					</button>
				</div>
			</div>
		</div>
	</div>

	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	<div class="card p-3">
		<table class="">
			<thead>
				<tr class="border-bottom">
					<th>Id</th>
					<th>Sucursal</th>
					<th>Referencia</th>
					<th>Creado por</th>
					<th>Fecha Inicio</th>
					<th>Fecha Final</th>
					<th>Creación</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let ssr of sales_store_report_list">
					<td>{{ssr.id}}</td>
					<td class="text-capitalize" >
						<a *ngIf="rest.has_ares" [routerLink]="['/view-sales-report',ssr.id,'h']">{{ssr.store.name}}</a>
						<a *ngIf="!rest.has_ares" [routerLink]="['/view-sales-report',ssr.id]">{{ssr.store.name}}</a>
					</td>
					<td>{{ssr.date}}</td>
					<td>{{ssr?.user.name}}</td>
					<td>{{ssr.start_timestamp| shortDate:'full'}}</td>
					<td>{{ssr.end_timestamp | shortDate:'full'}}</td>
					<td>{{ssr.created | shortDate:'full'}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
