import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { AuthGuard } from 'src/app/classes/auth.guard';
import { ClientGuard } from 'src/app/classes/client.guard';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import {SaveCategoryComponent} from './pages/save-category/save-category.component';
import {ListCategoryComponent} from './pages/list-category/list-category.component';
import {SaveItemComponent} from './pages/save-item/save-item.component';
import {ListItemComponent} from './pages/list-item/list-item.component';
import { ListOrderComponent } from './pages/list-order/list-order.component';
import {SavePreferencesComponent} from './pages/save-preferences/save-preferences.component';
import {SaveRequisitionComponent} from './pages/save-requisition/save-requisition.component';
import {ListRequisitionComponent} from './pages/list-requisition/list-requisition.component';
import {SavePriceTypeComponent} from './pages/save-price-type/save-price-type.component';
import {ListPriceTypeComponent} from './pages/list-price-type/list-price-type.component';
import {SaveStoreComponent} from './pages/save-store/save-store.component';
import {ListStoreComponent} from './pages/list-store/list-store.component';
import {SaveUserComponent} from './pages/save-user/save-user.component';
import {ListUserComponent} from './pages/list-user/list-user.component';
import {ListClientComponent} from './pages/list-client/list-client.component';
import {SaveClientComponent} from './pages/save-client/save-client.component';
import {ListStockComponent } from './pages/list-stock/list-stock.component';
import {ViewOrderComponent} from './pages/view-order/view-order.component';
import {EditPricesComponent} from './edit-prices/edit-prices.component';
import {EasyPosComponent} from './custom_pages/easy-pos/easy-pos.component';
import {AdjustStockComponent} from './pages/adjust-stock/adjust-stock.component';
import {ListPaymentComponent} from './pages/list-payment/list-payment.component';
import {PrintReceiptComponent} from './pages/print-receipt/print-receipt.component';
import {PrintCashClosingComponent} from './pages/print-cash-closing/print-cash-closing.component';
import {ListCommandaComponent} from './pages/list-commanda/list-commanda.component';
import {SaveCommandaComponent} from './pages/save-commanda/save-commanda.component';
import {ViewCommandaComponent} from './pages/view-commanda/view-commanda.component';
import {ListBillingDataComponent} from './pages/list-billing-data/list-billing-data.component';
import {SaveBillingDataComponent} from './pages/save-billing-data/save-billing-data.component';
import {SplitOrderComponent} from './pages/split-order/split-order.component';
import {SaveStockRecordComponent} from './pages/save-stock-record/save-stock-record.component';
import {ListPriceListComponent} from './pages/list-price-list/list-price-list.component';
import {SavePriceListComponent} from './pages/save-price-list/save-price-list.component';
import {ListAddressComponent} from './pages/list-address/list-address.component';
import {SaveAddressComponent} from './pages/save-address/save-address.component';
import {ListCommandaTypeComponent} from './pages/list-commanda-type/list-commanda-type.component';
import {SaveCommandaTypeComponent} from './pages/save-commanda-type/save-commanda-type.component';
import {ListPurchaseComponent} from './pages/list-purchase/list-purchase.component';
import {SavePurchaseComponent} from './pages/save-purchase/save-purchase.component';
import {ListStorageComponent} from './pages/list-storage/list-storage.component';
import {SaveStorageComponent} from './pages/save-storage/save-storage.component';
import {ListMermaComponent} from './pages/list-merma/list-merma.component';
import {DeliveryOrdersComponent} from './pages/delivery-orders/delivery-orders.component';
import {DeliveryOrderMapComponent} from './pages/delivery-order-map/delivery-order-map.component';
import {ClientSignupComponent} from './client/client-signup/client-signup.component';
import {ClientPosComponent} from './client/client-pos/client-pos.component';
import {ClientOrdersComponent} from './client/client-orders/client-orders.component';
import {ClientAddressComponent} from './client/client-address/client-address.component';
import {ClientSaveAddressComponent} from './client/client-save-address/client-save-address.component';
import {ClientSelectCategoryItemComponent} from './client/client-select-category-item/client-select-category-item.component';
import {ClientSelectCategoryComponent} from './client/client-select-category/client-select-category.component';
import {ClientConfigureSelectedItemComponent} from './client/client-configure-selected-item/client-configure-selected-item.component';
import {CCartComponent} from './client/c-cart/c-cart.component';
import {OrdersReportComponent} from './pages/orders-report/orders-report.component';
import {ClientViewOrderComponent} from './client/client-view-order/client-view-order.component';
import {CLoginComponent} from './client/c-login/c-login.component';
import {ClientChangeStoreComponent} from './client/client-change-store/client-change-store.component';
import {ClientSelectMapAddressComponent} from './client/client-select-map-address/client-select-map-address.component';
import {ClientFacturarComponent} from './client/client-facturar/client-facturar.component';
import {ListPriceComponent} from './pages/list-price/list-price.component';
import {ListCategoryPriceComponent} from './pages/list-category-price/list-category-price.component';
import {ListShippingComponent} from './pages/list-shipping/list-shipping.component';
import {SaveShippingComponent} from './pages/save-shipping/save-shipping.component';
import {ViewShippingComponent} from './pages/view-shipping/view-shipping.component';
import {ReportSellsByCategoryComponent} from './pages/report-sells-by-category/report-sells-by-category.component';
import {ReportSellsByItemComponent} from './pages/report-sells-by-item/report-sells-by-item.component';
import {ReportSellsByPriceComponent} from './pages/report-sells-by-price/report-sells-by-price.component';
import {PrintPaymentReceiptComponent} from './pages/print-payment-receipt/print-payment-receipt.component';
import {ListOrderWaiterComponent} from './pages/list-order-waiter/list-order-waiter.component';
import {ListReturnsComponent} from './pages/list-returns/list-returns.component';
import {PrintReturnReceiptComponent} from './pages/print-return-receipt/print-return-receipt.component';
import {PrintExpensePaymentComponent} from './pages/print-expense-payment/print-expense-payment.component';
import {ListPaymentExpensesComponent} from './pages/list-payment-expenses/list-payment-expenses.component';
import {OfflineResumeComponent} from './pages/offline-resume/offline-resume.component';
import {SaveQuoteComponent} from './pages/save-quote/save-quote.component';
import {ListQuoteComponent} from './pages/list-quote/list-quote.component';
import {ViewQuoteComponent} from './pages/view-quote/view-quote.component';
import {PrintRemissionComponent} from './pages/print-remission/print-remission.component';
import {PrintQuoteComponent} from './pages/print-quote/print-quote.component';
import {FacturacionPeriodoComponent} from './pages/facturacion-periodo/facturacion-periodo.component';
import {StockSearchReportComponent} from './pages/stock-search-report/stock-search-report.component';
import {ListStockRecordComponent} from './pages/list-stock-record/list-stock-record.component';
import {PrintCashClosing2Component } from './pages/print-cash-closing2/print-cash-closing2.component';
import {ListTableComponent} from './pages/list-table/list-table.component';
import {SaveTableComponent} from './pages/save-table/save-table.component';
import {TablesComponent} from './pages/tables/tables.component';
import { ListPriceLogComponent } from './pages/list-price-log/list-price-log.component';
import { PrintCommandaComponent } from './pages/print-commanda/print-commanda.component';
import { WeirdComponent } from './pages/weird/weird.component';
import { SaveCategoryStoreComponent } from './pages/save-category-store/save-category-store.component';
import { CorteXFolioComponent } from './pages/corte-x-folio/corte-x-folio.component';
import { SalesReportComponent } from './pages/sales-report/sales-report.component';
import { ListSalesReportComponent } from './pages/list-sales-report/list-sales-report.component';
import { ViewSalesReportComponent } from './pages/view-sales-report/view-sales-report.component';
import { CreateSalesReportComponent } from './pages/create-sales-report/create-sales-report.component';
import { ReportClientRefundsComponent } from './pages/report-client-refunds/report-client-refunds.component';
import { SaveProviderComponent } from './pages/save-provider/save-provider.component';
import { ListProviderComponent } from './pages/list-provider/list-provider.component';
import { ListProviderBillsComponent } from './pages/list-provider-bills/list-provider-bills.component';
import { HelpComponent } from './pages/help/help.component';
import { ListCategoryStoreComponent } from './pages/pages/list-category-store/list-category-store.component';
import { SaveItemStoreComponent } from './pages/save-item-store/save-item-store.component';
import { PrintReceipt2Component } from './pages/print-receipt2/print-receipt2.component';
import { ListOfferComponent } from './pages/list-offer/list-offer.component';
import { SaveOfferComponent } from './pages/save-offer/save-offer.component';
import { PostComponent } from './pages/post/post.component';
import { SavePostComponent } from './pages/save-post/save-post.component';
import { ErrorTableComponent } from './client/error-table/error-table.component';
import { PrintReceipt3Component } from './pages/print-receipt3/print-receipt3.component';
import { ListCashCloseComponent } from './components/list-cash-close/list-cash-close.component';
import { SaveSerialComponent } from './pages/save-serial/save-serial.component';
import { PrintReceipt5Component } from './pages/print-receipt5/print-receipt5.component';
import { CancelledOrdersComponent } from './pages/cancelled-orders/cancelled-orders.component';
import { AffiliateSalesComponent } from './pages/affiliate-sales/affiliate-sales.component';
import { MaxminReportComponent } from './pages/maxmin-report/maxmin-report.component';
import { ListUserOrderComponent } from './pages/list-user-order/list-user-order.component';
import { IncomeTypeReportComponent } from './pages/income-type-report/income-type-report.component';
import { PrintReceipt6Component } from './pages/print-receipt6/print-receipt6.component';
import { PrintCashClosing3Component } from './pages/print-cash-closing3/print-cash-closing3.component';
import { ItemInSalesReportComponent } from './pages/item-in-sales-report/item-in-sales-report.component';
import { ReportItemBomPriceComponent } from './pages/report-item-bom-price/report-item-bom-price.component';
import { ReportOrderItemProfitComponent } from './pages/report-order-item-profit/report-order-item-profit.component';
import { ReportsOccupancyComponent } from './pages/reports-occupancy/reports-occupancy.component';
import { ReportsItemProfitComponent } from './pages/reports-item-profit/reports-item-profit.component';
import { ReportWaiterOrdersComponent } from './pages/report-waiter-orders/report-waiter-orders.component';
import { ReportTicketAvgComponent } from './pages/report-ticket-avg/report-ticket-avg.component';
import { ReportOccupancyComponent } from './pages/report-occupancy/report-occupancy.component';
import { ViewPaymentComponent } from './pages/view-payment/view-payment.component';
import { PrintShippingComponent } from './pages/print-shipping/print-shipping.component';
import { SaveCurrencyRateComponent } from './pages/save-currency-rate/save-currency-rate.component';
import { SaveStocktakeComponent } from './pages/save-stocktake/save-stocktake.component';
import { ListStocktakeComponent } from './pages/list-stocktake/list-stocktake.component';
import { ViewStocktakeComponent } from './pages/view-stocktake/view-stocktake.component';
import { ViewRequisitionComponent } from './pages/view-requisition/view-requisition.component';
import { ListFormComponent } from './pages/list-form/list-form.component';
import { SaveFormComponent } from './pages/save-form/save-form.component';
import { ListResponseComponent } from './pages/list-response/list-response.component';
import { SaveResponseComponent } from './pages/save-response/save-response.component';
import { ViewClientComponent } from './pages/view-client/view-client.component';
import { SearchSerialComponent } from './pages/search-serial/search-serial.component';
import { ListBillComponent } from './pages/list-bill/list-bill.component';
import { SaveSerialBatchComponent } from './pages/save-serial-batch/save-serial-batch.component';
import { ListOrderFacturasComponent } from './pages/list-order-facturas/list-order-facturas.component';
import { SaldoClienteComponent} from './pages/saldo-cliente/saldo-cliente.component';
import { ListClientOrdersComponent } from './pages/list-client-orders/list-client-orders.component';
import { FacturaMultipleComponent } from './pages/factura-multiple/factura-multiple.component';
import { ReportSellsByProductionAreaComponent } from './pages/report-sells-by-production-area/report-sells-by-production-area.component';
import { ViewSalesProductionAreaComponent } from './pages/view-sales-production-area/view-sales-production-area.component';
import { PrintCancellationItemReceiptComponent } from './pages/print-cancellation-item-receipt/print-cancellation-item-receipt.component';
import { ListReturnedItemsComponent } from './pages/list-returned-items/list-returned-items.component';
import { ListSatFacturaComponent } from './pages/list-sat-factura/list-sat-factura.component';
// import { ListReportsComponent } from './pages/list-reports/list-reports.component';

const routes: Routes = [
	{ path:"", component: CLoginComponent, pathMatch: "full" },
	{ path:'admin', component: LoginComponent, pathMatch: "full" },
	{ path:'post/:id',component:PostComponent, pathMatch: 'full' },
	{ path:'login', component:CLoginComponent, pathMatch: "full" },
	{ path:'facturar',component:ClientFacturarComponent, pathMatch: 'full' },
	{ path:'registrarse', component: ClientSignupComponent, pathMatch:'full'},

	{ path:'print-receipt/1/:order_id', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/1/:order_id/:print', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/1/:order_id/:print/:copies', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-close/1/:order_id/force_close', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/1/:order_sync_id', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/1/:order_sync_id/:print', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/1/:order_sync_id/:print/:copies', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },

	{ path:'print-receipt/2/:order_id', component: PrintReceipt2Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/2/:order_id/:print', component: PrintReceipt2Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/2/:order_id/:print/:copies', component: PrintReceipt2Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/2/:order_sync_id', component: PrintReceipt2Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/2/:order_sync_id/:print', component: PrintReceipt2Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/2/:order_sync_id/:print/:copies', component: PrintReceipt2Component, pathMatch: 'full',canActivate:[AuthGuard] },


	{ path:'print-receipt/3/:order_id', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/3/:order_id/:print', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/3/:order_id/:print/:copies', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/3/:order_sync_id', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/3/:order_sync_id/:print', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/3/:order_sync_id/:print/:copies', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },

	{ path:'print-receipt/4/:order_id', component: PrintReceipt3Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/4/:order_id/:print', component: PrintReceipt3Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/4/:order_id/:print/:copies', component: PrintReceipt3Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-close/4/:order_id/force_close', component: PrintReceipt3Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/4/:order_sync_id', component: PrintReceipt3Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/4/:order_sync_id/:print', component: PrintReceipt3Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/4/:order_sync_id/:print/:copies', component: PrintReceipt3Component, pathMatch: 'full',canActivate:[AuthGuard] },


	{ path:'print-receipt/5/:order_id', component: PrintReceipt5Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/5/:order_id/:print', component: PrintReceipt5Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/5/:order_id/:print/:copies', component: PrintReceipt5Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-close/5/:order_id/force_close', component: PrintReceipt5Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/5/:order_sync_id', component: PrintReceipt5Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/5/:order_sync_id/:print', component: PrintReceipt5Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/5/:order_sync_id/:print/:copies', component: PrintReceipt5Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/6/:order_id', component: PrintReceipt6Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/6/:order_id/:print', component: PrintReceipt6Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/6/:order_id/:print/:copies', component: PrintReceipt6Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-close/6/:order_id/force_close', component: PrintReceipt6Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/6/:order_sync_id', component: PrintReceipt6Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/6/:order_sync_id/:print', component: PrintReceipt6Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/6/:order_sync_id/:print/:copies', component: PrintReceipt6Component, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/7/:order_id', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/7/:order_id/:print', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/7/:order_id/:print/:copies', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-close/7/:order_id/force_close', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/7/:order_sync_id', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/7/:order_sync_id/:print', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/7/:order_sync_id/:print/:copies', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },

	{ path:'print-receipt/undefined/:order_id', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/undefined/:order_id/:print', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt/undefined/:order_id/:print/:copies', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-close/undefined/:order_id/:print', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-receipt-offline/undefined/:order_sync_id/:print/:copies', component: PrintReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },

	{ path:'print-corte/1/:id', component: PrintCashClosingComponent, pathMatch: 'full' },
	{ path:'print-corte/1/:id/:cerrar', component: PrintCashClosingComponent, pathMatch: 'full' },
	{ path:'print-corte/1/:id/:cerrar/:print', component: PrintCashClosingComponent, pathMatch: 'full' },

	{ path:'print-corte/2/:id', component: PrintCashClosing2Component, pathMatch: 'full' },
	{ path:'print-corte/2/:id/:cerrar', component: PrintCashClosing2Component, pathMatch: 'full' },
	{ path:'print-corte/2/:id/:cerrar/:print', component: PrintCashClosing2Component, pathMatch: 'full' },

	{ path:'print-corte/3/:id', component: PrintCashClosing3Component, pathMatch: 'full' },
	{ path:'print-corte/3/:id/:cerrar', component: PrintCashClosing3Component, pathMatch: 'full' },
	{ path:'print-corte/3/:id/:cerrar/:print', component: PrintCashClosing3Component, pathMatch: 'full' },

	{ path:'print-cancellation-item-receipt/:returns_id/:print', component: PrintCancellationItemReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },

	{ path:'print-return-receipt/:returns_id', component: PrintReturnReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-return-receipt/:returns_id/:print', component: PrintReturnReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-payment-receipt/:payment_id',component:PrintPaymentReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-payment-receipt/:payment_id/:order_id',component:PrintPaymentReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-payment-receipt/:payment_id/:order_id/:print',component:PrintPaymentReceiptComponent, pathMatch: 'full',canActivate:[AuthGuard] },

	{ path:'print-payment-expense/:payment_id',component:PrintExpensePaymentComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-payment-expense/:payment_id/:print',component:PrintExpensePaymentComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-quote/:id',component:PrintQuoteComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-remission/:id',component:PrintRemissionComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path:'print-commanda/:id_commanda/:order_id/:commanda_print_type', component: PrintCommandaComponent, pathMatch: 'full',canActivate:[AuthGuard] },
	{ path: 'view-sales-report/:id',	component: ViewSalesReportComponent, pathMatch: 'full'},
	{ path: 'view-sales-report/:id/:h',	component: ViewSalesReportComponent, pathMatch: 'full'},
	{
		path: '', component: HomeComponent,
		children:
		[
			{ path:'', component: DashboardComponent}
			,{ path:'c-pos', component: ClientPosComponent, pathMatch:'full', canActivate:[ClientGuard]}
			,{ path:'c-orders', component: ClientOrdersComponent, pathMatch:'full', canActivate:[ClientGuard]}
			,{ path:'c-cart', component: CCartComponent, pathMatch:'full',canActivate:[ClientGuard]}
			,{ path:'c-cart/:table_id', component: CCartComponent, pathMatch:'full',canActivate:[ClientGuard]}
			,{ path:'c-address', component: ClientAddressComponent, pathMatch:'full',canActivate:[ClientGuard]}
			,{ path:'c-add-address', component: ClientSaveAddressComponent, pathMatch:'full',canActivate:[ClientGuard]}
			,{ path:'c-select-category', component:ClientSelectCategoryComponent, pathMatch:'full',canActivate:[ClientGuard]}
			,{ path:'c-select-category-item/:category_id', component:ClientSelectCategoryItemComponent , pathMatch:'full',canActivate:[ClientGuard]}
			,{ path:'c-configure-item/:item_id', component:ClientConfigureSelectedItemComponent, pathMatch: 'full',canActivate:[ ClientGuard] }
			,{ path:'c-view-order/:order_id', component:ClientViewOrderComponent, pathMatch: 'full',canActivate:[ ClientGuard] }
			,{ path:'c-change-store', component:ClientChangeStoreComponent, pathMatch: 'full',canActivate:[ ClientGuard ] }
			,{ path:'dashboard', component: DashboardComponent}

			,{ path:'list-client' , component: ListClientComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-commanda-type' , component: SaveCommandaTypeComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-commanda-type/:id' , component: SaveCommandaTypeComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-client' , component: SaveClientComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-client/:id' , component: SaveClientComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'view-client/:id' , component: ViewClientComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-category',component:ListCategoryComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-category/:type' , component: ListCategoryComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-category/:type' , component: SaveCategoryComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-category/:id' , component: SaveCategoryComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-item/:type' , component: ListItemComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-item' , component: ListItemComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-item/:type' , component: SaveItemComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-item' , component: SaveItemComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-item/:id' , component: SaveItemComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-order' , component: ListOrderComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'offline-resume' , component: OfflineResumeComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-order-waiter' , component: ListOrderWaiterComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-requisition' , component: ListRequisitionComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-requisition/:type' , component: ListRequisitionComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-requisition/:type' , component: SaveRequisitionComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-requisition/:id' , component: SaveRequisitionComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'view-requisition/:id' , component: ViewRequisitionComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-store' , component: ListStoreComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-store' , component: SaveStoreComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-store/:id', component: SaveStoreComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-stock/:type' , component: ListStockComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-stock' , component: ListStockComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-user' , component: ListUserComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'user' , component: SaveUserComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'user/:id' , component: SaveUserComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-preferences' , component: SavePreferencesComponent, pathMatch: 'full',canActivate:[AuthGuard] }

			,{ path:'pos',component: EasyPosComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'pos/:id', component: EasyPosComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'pos/table/:table_id', component: EasyPosComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'pos/pay/:make_payment/:id', component: EasyPosComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'pos/pay-offline/:make_payment/:sync_id', component: EasyPosComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'pos/offline/:sync_id', component: EasyPosComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'pos/client/:client_id', component: EasyPosComponent, pathMatch: 'full',canActivate:[AuthGuard] }

			,{ path:'view-order/:id', component: ViewOrderComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'view-order/:sync_id/:offline', component: ViewOrderComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'adjust-stock/:item_id', component: AdjustStockComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-payment', component: ListPaymentComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-payment-expenses', component: ListPaymentExpensesComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-commanda', component: ListCommandaComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-commanda-type', component: ListCommandaTypeComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-commanda', component: SaveCommandaComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-commanda/:id', component: SaveCommandaComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'view-commanda/:id', component: ViewCommandaComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-billing-data', component: ListBillingDataComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-shipping', component: ListShippingComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-shipping', component: SaveShippingComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-shipping-requisition/:requisition_id', component: SaveShippingComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-shipping/:id', component: SaveShippingComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'view-shipping/:shipping_id', component: ViewShippingComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-billing-data', component: SaveBillingDataComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-billing-data/:id', component: SaveBillingDataComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'split-order/:id', component: SplitOrderComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-stock-record', component: SaveStockRecordComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-price-type' , component: ListPriceTypeComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-price-type' , component: SavePriceTypeComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-price-type/:id' , component: SavePriceTypeComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-prices/:item_id' , component: EditPricesComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-price-list/:id', component: SavePriceListComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-price/:price_list_id', component: ListPriceComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-price/:price_list_id/:name', component: ListPriceComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-category-price/:price_list_id', component: ListCategoryPriceComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-serial-batch', component: SaveSerialBatchComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-serial-batch/:item_id', component: SaveSerialBatchComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-client-orders', component: ListClientOrdersComponent, pathMatch: 'full',canActivate:[AuthGuard] }
/*			,{ path:'list-category-price/:price_list_id/:name', component: ListCategoryPriceComponent, pathMatch: 'full',canActivate:[AuthGuard] } */
			,{ path:'list-price-list', component: ListPriceListComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-price-list', component: SavePriceListComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-address', component: ListAddressComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-address/:user_id', component: SaveAddressComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-address/:id', component: SaveAddressComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-purchase', component: ListPurchaseComponent, pathMatch: 'full',canActivate:[AuthGuard] }

			,{ path:'purchase', component: SavePurchaseComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'purchase/edit/:id', component: SavePurchaseComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'purchase/provider/:provider_id', component: SavePurchaseComponent, pathMatch: 'full',canActivate:[AuthGuard] }

			,{ path:'list-storage', component: ListStorageComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-storage', component: SaveStorageComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-storage/:id', component: SaveStorageComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-merma', component: ListMermaComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'delivery-orders', component:DeliveryOrdersComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'delivery-order-map/:order_id', component:DeliveryOrderMapComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'orders-report',component:OrdersReportComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'c-select-map-address/:address',component:ClientSelectMapAddressComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'report-sells-by-category',component:ReportSellsByCategoryComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'report-sells-by-item',component:ReportSellsByItemComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'report-sells-by-price',component:ReportSellsByPriceComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'report-income-by-type',component:IncomeTypeReportComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-returns',component:ListReturnsComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-quotes',component:ListQuoteComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-quote/:id',component:SaveQuoteComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'quote/:id',component:SaveQuoteComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'quote',component:SaveQuoteComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-quote',component:SaveQuoteComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'view-quote/:id',component:ViewQuoteComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-currency-rates', component:SaveCurrencyRateComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'facturacion-periodo',component:FacturacionPeriodoComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'stock-search-report',component:StockSearchReportComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-stock-record',component:ListStockRecordComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-stocktake',component:ListStocktakeComponent, pathMatch: 'full',canActivate:[AuthGuard]}
			,{ path:'edit-stocktake/:stocktake_id',component:SaveStocktakeComponent, pathMatch: 'full',canActivate:[AuthGuard]}
			,{ path:'view-stocktake/:stocktake_id',component:ViewStocktakeComponent, pathMatch: 'full',canActivate:[AuthGuard]}
			,{ path:'list-table',component:ListTableComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'add-table',component:SaveTableComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'edit-table/:id',component: SaveTableComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'tables',component:TablesComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-price-log',component:ListPriceLogComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'weird',component:WeirdComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'category-store',component:SaveCategoryStoreComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'corte-x-folio',component:CorteXFolioComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'corte-x-folio/:date',component:CorteXFolioComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'sales-report',component:SalesReportComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-sales-report', component: ListSalesReportComponent, pathMatch: 'full' }
			,{ path:'create-sales-report',	component: CreateSalesReportComponent, pathMatch: 'full'}
			,{ path:'report-client-refunds',	component: ReportClientRefundsComponent, pathMatch: 'full'}
			,{ path:'provider',	component: SaveProviderComponent, pathMatch: 'full'}
			,{ path:'provider/:id',	component: SaveProviderComponent, pathMatch: 'full'}
			,{ path:'list-provider',	component: ListProviderComponent, pathMatch: 'full'}
			,{ path:'list-provider-bills/:id',	component: ListProviderBillsComponent, pathMatch: 'full'}
			,{ path:'help',	component: HelpComponent, pathMatch: 'full'}
			,{ path:'list-category-store',	component: ListCategoryStoreComponent, pathMatch: 'full'}
			,{ path:'item-store',component:SaveItemStoreComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'list-offer',component:ListOfferComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'offer',component:SaveOfferComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'offer/:id',component:SaveOfferComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'post',component:SavePostComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'post/:id',component:SavePostComponent, pathMatch: 'full',canActivate:[AuthGuard] }
			,{ path:'geolocation-error',component:ErrorTableComponent, pathMatch: 'full' }
			,{ path:'list-cash-close/:user_id',component:ListCashCloseComponent, pathMatch: 'full', canActivate:[AuthGuard] }
			,{ path:'serial/:item_id',component:SaveSerialComponent, pathMatch: 'full', canActivate:[AuthGuard] }
			,{ path:'cancelled-orders',component:CancelledOrdersComponent, pathMatch: 'full', canActivate:[AuthGuard] }
			,{ path:'affiliates-sales',component:AffiliateSalesComponent, pathMatch: 'full', canActivate:[AuthGuard] }
			,{ path:'maxmin-report',component:MaxminReportComponent, pathMatch: 'full', canActivate:[AuthGuard] }
			,{ path:'list-user-orders',component:ListUserOrderComponent, pathMatch: 'full', canActivate:[AuthGuard] }
			,{ path:'item-in-sales-report', component: ItemInSalesReportComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'report-item-bom-price', component: ReportItemBomPriceComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'report-order-item-profit', component: ReportOrderItemProfitComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'reports-occupancy', component: ReportsOccupancyComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'reports-item-profit', component: ReportsItemProfitComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'report-waiter-orders', component: ReportWaiterOrdersComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'report-ticket-avg', component: ReportTicketAvgComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'report-occupancy', component: ReportOccupancyComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'view-payment/:payment_id', component: ViewPaymentComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'print-shipping/:shipping_id', component: PrintShippingComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'list-form', component: ListFormComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'add-form', component: SaveFormComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'edit-form/:id', component: SaveFormComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'add-response/:form_id', component: SaveResponseComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'add-response/:form_id/:user_id', component: SaveResponseComponent, pathMatch: 'full', canActivate: [AuthGuard ]}
			,{ path:'edit-response/:id', component: SaveResponseComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'list-response/:form_id', component: ListResponseComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'search-serial', component: SearchSerialComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'list-bill', component: ListBillComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'list-order-facturas', component: ListOrderFacturasComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'saldo-razon-social', component: SaldoClienteComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'factura-multiple', component: FacturaMultipleComponent , pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'report-sells-by-production-area', component: ReportSellsByProductionAreaComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'view-sales-production-area/:id', component: ViewSalesProductionAreaComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path: 'list-returned-items', component: ListReturnedItemsComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			,{ path:'list-sat-factura', component: ListSatFacturaComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
			// ,{ path:'list-reports', component: ListReportsComponent, pathMatch: 'full', canActivate: [AuthGuard ] }
		]
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash:true})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
