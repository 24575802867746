<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-10">
			<h1 class="my-3">Reporte ventas por Artículo</h1>
		</div>
		<div class="col-2 d-print-none text-end">
			<button type="button" class="btn btn-secondary" (click)="export()">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m2.859 2.878l12.57-1.796a.5.5 0 0 1 .571.495v20.847a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.868a1 1 0 0 1 .859-.99ZM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3Zm-6.8 9L13 8h-2.4L9 10.286L7.4 8H5l2.8 4L5 16h2.4L9 13.714L10.6 16H13l-2.8-4Z"/></svg>
			</button>
			<button type="button" class="btn btn-secondary"	(click)="print()">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 8H5c-1.66 0-3 1.34-3 3v4c0 1.1.9 2 2 2h2v2c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-2h2c1.1 0 2-.9 2-2v-4c0-1.66-1.34-3-3-3zm-4 11H9c-.55 0-1-.45-1-1v-4h8v4c0 .55-.45 1-1 1zm4-7c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1zm-2-9H7c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1z"/></svg>
			</button>
		</div>
	</div>
	<div class="card px-lg-3 px-1 py-3 d-print-none">
		<div class="row">
			<div class="col-6 form-group">
				<label>Nombre</label>
				<input type="text" name="compound_name" [(ngModel)]="report_search.eq.compound_name" class="form-control">
			</div>
			<div class="col-6 form-group">
				<label>Sucursal</label>
				<select [(ngModel)]="report_search.eq.store_id" class="form-control">
					<option value="">Todos</option>
					<option *ngFor="let store of store_list" [value]="store.id">{{store.name | titlecase}}</option>
				</select>
			</div>
			<div class="col-6 form-group">
				<label>Categoría</label>
				<select [(ngModel)]="report_search.eq.category_id" class="form-control">
					<option value="">Todas</option>
					<option *ngFor="let category of category_list" [value]="category.id">{{ category.name | titlecase }}</option>
				</select>
			</div>

			<div class="col-6 form-group">
				<label>Fecha Inicial</label>
				<input type="datetime-local" name="startx" [(ngModel)]="startx" (ngModelChange)="onDateChange($event,report_search.ge,'created')" class="form-control d-print-none">
			</div>
			<div class="col-6 form-group">
				<label>Fecha final</label>
				<input type="datetime-local" name="endx" [(ngModel)]="endx"	(ngModelChange)="onDateChange($event,report_search.le,'created','',59)" class="form-control d-print-none">
			</div>
			<div class="col-6 form-group">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary" (click)="searchReport()">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						Buscar
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-loading [is_loading]="is_loading"></app-loading>
	<div class="card my-3 py-3 px-1 px-lg-3">
		<div class="row fw-bold">
			<div class="col-4" (click)="sortByName()">Artículo</div>
			<div class="col-4 text-end" (click)="sortByQty()">Total Vendidos</div>
			<div class="col-4 text-end" (click)="sortByTotal()">Total</div>
		</div>
		<div class="row	border-bottom" *ngFor="let r of report_list">
			<div class="col-4">
				<span *ngIf="r.category_name">{{r.category_name }} - </span>
				<span class="fw-bold">{{r.item_name}}</span>
				<span style="color:#888888">&nbsp;{{r.item_code}}</span>
			</div>
			<div class="col-4 text-end">
				{{r.order_item_qty | number}}
			</div>
			<div class="col-4 text-end">
				{{r.order_item_total | currency}}
			</div>
		</div>
		<div class="row fw-bold">
			<div class="col-4">Total</div>
			<div class="col-4 text-end">{{total_qty | number}}</div>
			<div class="col-4 text-end">{{total | currency}}</div>
		</div>
	</div>
</div>
