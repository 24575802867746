import { Component, OnInit } from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { NumeroALetras } from 'src/app/classes/NumeroALetras';
import {ItemOptionInfo, OrderInfo, OrderItemInfo, QuoteInfo, QuoteItemInfo, UserBalance} from 'src/app/models/models';
import {Address, Currency, Item_Exception, Order, Order_Item, Order_Item_Exception, Price, Stock_Record, Store} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-view-quote',
	templateUrl: './view-quote.component.html',
	styleUrls: ['./view-quote.component.css']
})
export class ViewQuoteComponent extends BaseComponent implements OnInit
{
	quote_info:QuoteInfo | null = null;
	total:number = 0;
	store_list: Store[];
	billing_address: Address | null = null;

	show_resend_quote:boolean = false;
	resend_quote_name:string = '';
	resend_quote_email:string = '';
	billing_data = GetEmpty.billing_data();
	total_letra:string = '';
	currency:Currency = GetEmpty.currency();
	currency_list: Currency[];
	total_tax: number = 0;
	store:Store = GetEmpty.store();
	user_balance:UserBalance = null;

	subtotal:number = 0;

	ngOnInit(): void
	{
		this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
				return forkJoin
				({
					stores: this.rest.getStores(),
					currencies: this.rest.currency.search({limit:99999}),
					quote_info:this.rest.quote_info.get(param_map.get('id'))
				})
			}),
			mergeMap((responses)=>
			{
				this.currency_list = responses.currencies.data;
				this.store = responses.stores.data.find(s=>s.id == responses.quote_info.quote.store_id) as Store;

				let obs:Observable<Address | null> | null = null;

				if( !responses.quote_info?.client_user )
				{
					obs = of(null);
				}
				else
				{
					if( responses.quote_info.client_user.default_billing_address_id )
					{
						obs = this.rest.address.get( responses.quote_info.client_user.default_billing_address_id);
					}
					else ( responses.quote_info?.client_user )
					{
						obs = this.rest.address.search
						({
							eq		:{ user_id:responses.quote_info.client_user.id },
							nn		:['rfc'],
							limit	:1
						})
						.pipe
						(
							mergeMap((response)=>
							{
								if( response.total > 0 )
								{
									return of( response.data[0] );
								}
								else
								{
									return of( null );
								}
							})
						);
					}
				}

				return forkJoin({
					quote_info:of(responses.quote_info),
					stores: of( responses.stores ),
					address: obs,
					billing_data: this.rest.billing_data.get( this.store.default_billing_data_id),
					balance: this.searchUserBalance( responses.quote_info.quote.client_user_id )
				})
			})
		).subscribe((response)=>
		{
			this.currency = this.currency_list.find(c=>c.id == response.quote_info.quote.currency_id) as Currency;
			this.billing_data = response.billing_data;
			this.billing_address = response.address;
			this.store_list = response.stores.data;
			this.quote_info = response.quote_info;
			this.total = response.quote_info.items.reduce((p,i)=>p+i.quote_item.total,0);
			this.total_tax = response.quote_info.items.reduce((p,i)=>p+i.quote_item.tax, 0);
			this.subtotal = response.quote_info.items.reduce((p,i)=>p+i.quote_item.subtotal,0);
			let n = new NumeroALetras('','','/100','/100');
			this.total_letra = n.numeroALetras( this.total );
			this.user_balance = response.balance;

		},(error)=>this.showError(error));
	}

	printDirect(evt:Event)
	{
		this.print( evt );
	}

	print(evt:Event)
	{
		this.rest.hideMenu();
		setTimeout(()=>{
			console.log( evt );
			window.print();
		},200);

		//let mes = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Nombiembre','Diciembre'];
		//generatePDF(
		//			''+this.order_info.order.created.getFullYear()+'-'
		//			+mes[ this.order_info.order.created.getMonth()]+'-'
		//			+this.order_info.order.id+'-'
		//			+this.order_info.order.client_name+'-'
		//			+Utils.zero( this.order_info.order.created.getDate())
		//			);
		//console.log('try to save');

		//var doc = new jsPDF({
		//	orientation: 'p',
		//	format:'letter',
		//})

		//doc.setFontSize(12);
		//doc.html(document.body, {
		//	callback: function (doc) {
		//		doc.save();
		//	},
		//	x: 0,
		//	y: 0
		//});

	//	generatePDF('miname.pdf');
		//const doct = new jsPDF();
		//const specialElementHandlers = {
		//	'#pdf': function(element,renderer ){
		//		return true;
		//	}
		//};
		//
	}

	getOrderItemFromQuoteItem(qii: QuoteItemInfo, item_group: number, sat_ieps: number): OrderItemInfo
	{
		let order_item = GetEmpty.order_item(qii.item);

		order_item.tax_included = qii.quote_item.tax_included;
		// Hay un error en la cotización los precios los pone mal
		// Como siempre los pone con iva lo ponemos hardcoded HERE
		order_item.discount = qii.quote_item.discount;
		order_item.discount_percent = qii.quote_item.discount_percent;
		order_item.subtotal = qii.quote_item.subtotal;
		order_item.total = qii.quote_item.total;
		order_item.qty = qii.quote_item.qty;
		order_item.tax = qii.quote_item.tax;
		order_item.status = qii.quote_item.status;
		order_item.unitary_price = qii.quote_item.unitary_price;
		order_item.unitary_price_meta = qii.quote_item.unitary_price_meta;
		order_item.original_unitary_price = qii.quote_item.original_unitary_price;

		return {
			order_item,
			item: qii.item,
			category: qii.category,
			prices: [] as Price[],
			records: [] as Stock_Record[],
			options: [] as ItemOptionInfo[],
			exceptions: [] as Item_Exception[],
			order_item_exceptions: [] as Order_Item_Exception[],
			serials: [],
			serials_string: '',
			created: new Date(),
			commanda_type_id: qii.item.commanda_type_id,
		};
	}


	searchUserBalance(user_id:number):Observable<UserBalance|null>
	{
		if( this.rest.is_offline )
			return of( null );

		return this.rest.user.search({csv:{ id:[ user_id ]},limit:1}).pipe
		(
			mergeMap((response)=>{

				if( response.data.length )
					return of( response.data[0] );

				return of( null );
			})
		);
	}

	startCreateOrderProcess()
	{
		let send_to_credit:boolean;

		//comparar si la cotizacion tiene client_user_id
		if( this.quote_info?.quote?.client_user_id )
		{
			//comparar si el cliente tiene credito suficiente para la compra
			let balance_futuro = Math.abs( this.user_balance.balance - this.total );

			if( this.quote_info.client_user.credit_limit > balance_futuro )
			{
				send_to_credit = true;
				this.createOrder(send_to_credit);
			}
			else
			{
				//mostrar modal para confirmar la compra
				this.subs.sink = this.confirmation.showConfirmAlert
				(
					this.quote_info,
					'Credito Insuficiente',
					'Verifique los adeudos del cliente o incremente su limite de credito.',
					'Crear orden a contado'
				)
				.subscribe((response)=>
				{
					if( response.accepted )
					{
						send_to_credit = false;
						this.createOrder(send_to_credit);
					}
				});
			}
		}
		else
		{
			console.log('No tiene cliente, creando orden');
			send_to_credit = false;
			this.createOrder(send_to_credit);
		}
	}

	createOrder(send_to_credit: boolean) {
		this.is_loading = true;
		let version_created = this.rest.getVersion();

		let order: Order = {
			quote_id: this.quote_info.quote.id as number,
			store_id: this.quote_info.quote.store_id,
			sat_exchange_rate: 22,
			tax_percent: this.quote_info.quote.tax_percent,
			total: this.total,
			cancellation_timestamp: null,
			delivery_schedule: null,
			closed_timestamp: null,
			discount: 0,
			discount_calculated: 0,
			status: 'CLOSED',
			facturacion_timestamp: null,
			ares: 0,
			address: '',
			amount_paid: 0,
			authorized_by: null,
			billing_data_id: null,
			billing_address_id: null,
			cancellation_reason: '',
			cancelled_by_user_id: null,
			city: '',
			client_name: this.quote_info.quote.name,
			delivery_status: 'PENDING',
			delivery_user_id: null,
			facturacion_code: '',
			facturado: 'NO',
			sat_factura_id: null,
			sat_isr: 0,
			sat_ieps: 0,
			tag: '',
			tax: 0,
			guests: 1,
			lat: null,
			lng: null,
			table_id: null,
			marked_for_billing: 'NO',
			paid_status: 'PENDING',
			sat_domicilio_fiscal_receptor: '',
			sat_regimen_capital_receptor: '',
			sat_regimen_fiscal_receptor: '',
			cashier_user_id: this.rest.current_user.id,
			client_user_id: this.quote_info.quote.client_user_id,
			price_type_id: this.quote_info?.client_user?.price_type_id || 1,
			period_id: null,
			sync_id: this.rest.getSyncId(),
			currency_id: this.quote_info.quote.currency_id,
			service_type: 'QUICK_SALE',
			system_activated: null,
			created: new Date(),
			updated: new Date(),
			version_created,
			version_updated: version_created
		};

		let item_group: number = Date.now();

		console.log('Creando ', order.currency_id);

		let order_info: OrderInfo = {
			order: order,
			client: null,
			purchase: null,
			cashier: this.rest.current_user,
			sat_factura: null,
			delivery_user: null,
			items: this.quote_info.items
				.filter((i) => i.quote_item.status == 'ACTIVE')
				.map((i, index: number) => this.getOrderItemFromQuoteItem(i, item_group + index, order.sat_ieps)),
			structured_items: [],
			store: GetEmpty.store(),
			offers: []
		};

		this.subs.sink = this.rest.order_info
		.create(order_info)
		.pipe
		(
			mergeMap((response) =>
			{
				return forkJoin
				({
					order_info: of(response),
					update: this.rest.setOrderActive(response.order.id)
				});
			}),
			mergeMap((response) => {
				this.is_loading = false;
				this.showSuccess('La orden se ha creado correctamente');

				let payload = { order_id: response.order_info.order.id };
				let closed_log = (r): Observable<any> => {
					console.log('Orden Cerrada');
					return of(null);
				};

				let obs = !send_to_credit
					? of(null)
					: this.rest.update('closeOrder', payload).pipe(
						mergeMap(closed_log),
						catchError((e) => of(null))
					);

				return forkJoin({
					order_info: of(response.order_info),
					closed: obs
				});
			})
		)
		.subscribe
		(
			(response) =>
			{
				this.router.navigate(['/view-order', response.order_info.order.id]);
			},
			(error) => this.showError(error)
		);
	}

	showSendQuote()
	{
		this.resend_quote_name = this.quote_info.quote.name;
		this.resend_quote_email = this.quote_info.quote.email;
		this.show_resend_quote = true;
	}

	sendQuote(evt:Event)
	{
		this.is_loading = true;
		let x:any = evt

		let client_name = this.quote_info.quote.name;
		let email = this.resend_quote_email;
		let quote_id = this.quote_info.quote.id;
		let date = new Date().toISOString().split('T')[0];
		let valid_until = this.quote_info.quote.valid_until;
		let phone = this.quote_info.quote.phone;
		let total = this.total;
		let image_html = '';
		let phone_html = '';
		let currency = this.currency.name;

		if( phone )
		{
			phone_html = `      <tr>
									<th style="text-align:left" >Teléfono:</th>
									<td>${phone}</td>
							  </tr>`;
		}

		if( this.store.ticket_image_id || this.rest.local_preferences.default_ticket_image_id )
		{
			let path = this.rest.getImagePath(this.store.ticket_image_id, this.rest.local_preferences.default_ticket_image_id);
			image_html = `<img src="${path}" style="width:150px;aspect-ratio: auto;">`;
		}

		let html = `<DOCTYPE html>
		<html>
		<head></head>
		<body>
				${image_html}
				<table style="width:100%;text-align:left">
					<tr>
						<td style="width:50%">
							<table style="text-align:left">
								<tr>
									<th style="text-align:left">Nombre:</th>
									<td>${client_name}</td>
								</tr>
								<tr>
									<th style="text-align:left" >Correo:</th>
									<td>${email}</td>
								</tr>
								${phone_html}
							</table>
						</td>
						<td style="width:50%">
							<table style="text-align:left;float:right">
								<tr>
									<th style="text-align:left">Cotización:</th>
									<td>NO. ${quote_id}</td>
								</tr>
								<tr>
									<th style="text-align:left">Fecha de elaboración:</th>
									<td>${date}</td>
								</tr>
								<tr>
									<th style="text-align:left">Vigencia:</th>
									<td>${valid_until}</td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
				<hr>
				<table style="width:100%;text-align:left">
					<thead class="thead-light">
						<tr class="table-secondary">
							<th style="text-align:left">Artículo</th>
							<th style="text-align:right">Cantidad</th>
							<th style="text-align:right">Precio</th>
							<th style="text-align:right">Total</th>
						</tr>
					</thead>
					<tbody>`;

		this.quote_info.items.forEach((i) =>
		{
			html += `
						<tr>
							<td>${i.item.name}</td>
							<td style="text-align:right">${i.quote_item.qty}</td>
							<td style="text-align:right">${i.quote_item.unitary_price}</td>
							<td style="text-align:right">${i.quote_item.total}</td>
						</tr>`;
		});

		html += `
					</tbody>
					<tfoot class="thead-light">
						<tr class="table-secondary">
							<th colspan="3" style="text-align:right">Total</th>
							<th style="text-align:right">${total}</th>
						</tr>
					</tfoot>
				</table>
				<h3>TERMINOS Y CONDICIONES</h3>
				<ul>
					<li>PRECIO EXPRESADO EN: <b>${currency.toUpperCase()}</b>
					<li>CONDICIONES DE PAGO: <b>CONTADO</b>
				</ul>
			</body>
		</html>`;

		let html_object =
		{
			html: html,
			orientation: 'P',
			default_font_size: 9,
			default_font: 'Helvetica',
			download_name: ''
		}

		let url = environment.app_settings.pdf_service_url + "/index.php";

		this.subs.sink = this.rest.externalPost(url, html_object)
		.pipe
		(
			mergeMap((response) =>
			{
				const pdf_file = new File([response], 'cotizacion.pdf', { type: 'application/pdf' });

				const formData = new FormData();
				formData.append('pdf', pdf_file);
				formData.append('quote_id', this.quote_info.quote.id.toString());
				formData.append('email', this.resend_quote_email);
				formData.append('name', this.resend_quote_name || '');

				return this.rest.send_quote.create(formData);
			})
		)
		.subscribe
		({
			next: (response) =>
			{
				this.showSuccess('El correo se envió correctamente');
				this.is_loading = false;
				x.target.reset();
			},
			error: (error) =>
			{
				this.showError('Error al enviar el correo');
				this.is_loading = false;
			}
		});
	}
}
