import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {forkJoin} from 'rxjs';
import {KeyboardShortcutEvent} from 'src/app/classes/Utils';
import {OrderInfo, OrderItemInfo, ReturnsInfo,ReturnItemInfo} from 'src/app/models/models';
import {BaseComponent} from 'src/app/pages/base/base.component';

interface SubmitEvent extends Event
{
	/**
	* Returns the element representing the submit button that triggered the form submission, or null if the submission was not triggered by a button.
	*/
	readonly submitter: HTMLElement | null;
}

interface CReturnItemInfo extends ReturnItemInfo
{
	price:number;
	max_qty:number;
}

@Component({
	selector: 'app-make-order-return',
	templateUrl: './make-order-return.component.html',
	styleUrls: ['./make-order-return.component.css']
})

export class MakeOrderReturnComponent extends BaseComponent implements OnChanges
{
	@Input() order_id: number;
	@Output() onOrderReturned = new EventEmitter<ReturnsInfo>();
	@Output() onCancel = new EventEmitter<void>();

	order_info:OrderInfo | null = null;
	previous_returns:ReturnsInfo[] = [];
	returns_info:Partial<ReturnsInfo> | null = null;

	max_qty_by_item_id:Record<number,number> = {};
	price_dictionary_by_item_id:Record<number,number> = {};

	ngOnInit(): void
	{
		this.subs.sink = this.shortcuts.shortcuts.subscribe
		((kse:KeyboardShortcutEvent)=>
		{
			if( kse.shortcut.key_combination == 'Escape' && this.is_loading == false )
			{
				this.onCancel.emit();
			}
		})
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		if( changes.order_id )
		{
			this.is_loading = true;
			this.subs.sink = forkJoin
			({
				order_info: this.rest.order_info.get( this.order_id ),
				returns_info: this.rest.returns_info.search({ eq: { order_id: this.order_id },limit: 99999 })
			})
			.subscribe
			(
				(responses) =>
				{
					this.is_loading = false;
					this.max_qty_by_item_id = {};
					this.price_dictionary_by_item_id = {};

					if( ['CANCELLED','PENDING'].includes(responses.order_info.order.status) )
					{
						this.showError('La orden esta cancelada o nunca fue entregada');
						this.onCancel.emit();
						return;
					}

					responses.order_info.items.forEach((oii:OrderItemInfo)=>
					{
						if( oii.order_item.is_free_of_charge == 'NO' )
						{
							if( oii.order_item.item_id in this.max_qty_by_item_id )
							{
								console.log('Adding ',oii.order_item.qty );
								this.max_qty_by_item_id[oii.order_item.item_id] += oii.order_item.qty;
							}
							else
							{
								console.log('settinghg ',oii.order_item.qty );
								this.max_qty_by_item_id[oii.order_item.item_id] = oii.order_item.qty;
							}

							if( !(oii.order_item.item_id in this.price_dictionary_by_item_id) )
							{
								this.price_dictionary_by_item_id[oii.order_item.item_id] = oii.order_item.total/oii.order_item.qty;
							}
						}
					});

					this.order_info = responses.order_info;

					this.returns_info = {
						returns:{
							order_id: this.order_id,
							currency_id: this.order_info.order.currency_id,
							//XXX esto esta mal es de la sucursal donde hicieron la devolucion
							store_id: this.order_info.order.store_id,
							type: 'RETURN_MONEY',
						},
						items:[],
					}
				},
				(error) =>
				{
					this.showError( error );
				}
			);
		}
	}

	addItem(oii:OrderItemInfo)
	{
		let returned_item = this.returns_info.items.find((rii:any)=>
		{
			return rii.returned_item.item_id == oii.order_item.item_id
		});

		if( returned_item )
		{
			console.log('found',returned_item);

			if( returned_item.returned_item.returned_qty < this.max_qty_by_item_id[oii.order_item.item_id] )
			{
				returned_item.returned_item.returned_qty += 1;
			}
		}
		else
		{
			this.returns_info.items.push({
				returned_item:
				{
					item_id: oii.order_item.item_id,
					returned_qty: 1,
					total: oii.order_item.total/oii.order_item.qty
				},
				item: oii.item,
				category: oii.category,
			});
		}

		this.updateTotal();
	}

	updateTotal()
	{
		let total:number = 0;
		this.returns_info.items.forEach((ri)=>{
			ri.returned_item.total = ri.returned_item.returned_qty * this.price_dictionary_by_item_id[ri.returned_item.item_id];
			total += ri.returned_item.total;
		})

		this.returns_info.returns.total = total;
	}

	cancel()
	{
		console.log('somtehign');
		this.onCancel.emit();
	}

	save(evt:SubmitEvent)
	{
		this.is_loading = true;

		let submiter = evt.submitter as HTMLButtonElement;
		this.returns_info.returns.type = submiter.value as ('RETURN_MONEY' | 'RETURN_COUPON');

		this.subs.sink = this.rest.returns_info
		.create( this.returns_info )
		.subscribe((response)=>
		{
			console.log( evt );

			if( this.returns_info.returns.type == 'RETURN_MONEY' )
			{
				let x = window.open('/#/print-cancellation-item-receipt/'+response.returns.id+'/1?print=1');
				this.rest.showSuccess('La informacioon se guardo correctamente, ya puedes devolver el dinero')
			}
			else
			{
				let x = window.open('/#/print-return-receipt/'+response.returns.id+'/1?print=1');
			}
			console.log('A imprimir', response );
			this.onOrderReturned.emit( response );
		},(error)=>this.showError(error));
	}
}
