import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import {CashCloseInfo, PaymentInfo, ReturnsInfo} from 'src/app/models/models';
import {Bank_Movement, Billing_Data, Currency_Rate, Fund, Payment, Store} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';

interface Equivalencia{
	total:number;
	currency_id:string;
	equivalencia:number;
}

@Component({
	selector: 'app-print-cash-closing2',
	templateUrl: './print-cash-closing2.component.html',
	styleUrls: ['./print-cash-closing2.component.css']
})

export class PrintCashClosing2Component extends BaseComponent implements OnInit
{
	cash_close_info:CashCloseInfo = null;
	current_date:Date = new Date();

	total_sales:number			= 0;
	total_credit:number			= 0;
	total_discount:number		= 0;

	item_total:number			= 0;
	currencies_total:number		= 0;
	total_income_sales:number	= 0;
	total_income_other:number	= 0;
	total_order_amount_paid:number = 0;
	order_counter:number		= 0;
	total_caja:number			= 0;
	total_funds:number			= 0;
	total_expenses				= 0;
	expenses_paymens:Payment[] = [];
	print_and_close:boolean = false;
	print_listener = ()=>{ window.close() };

	returns_info_list: ReturnsInfo[] = [];
	total_returns: number = 0;

	currency_rate:Currency_Rate = {
		id: 0,
		rate: 19,
		store_id: 0,
		currency_id: 'USD'
	}
	movements_qtys:Record<string,Equivalencia> = {
		'Tarjeta Crédito':{ total: 0, currency_id:'MXN', equivalencia: 0 },
		'Tarjeta Débito':{ total: 0, currency_id:'MXN', equivalencia: 0 },
		Transferencia:{ total: 0, currency_id:'MXN', equivalencia: 0 },
		Cheque:{ total: 0, currency_id:'MXN', equivalencia: 0 }
	};

	legend = {
		'CASH-MXN':'Efectivo',
		'CASH-USD':'Dolares',
		'CREDIT_CARD-MXN':'Tarjeta Crédito',
		'DEBIT_CARD-MXN':'Tarjeta Débito',
		'CHECK-MXN':'Cheque',
		'TRANSFER-MXN':'Transferencia',
	}

	payment_movements:Bank_Movement[] = [];

	d: number = Math.random();
	billing_data: Billing_Data | null = null;
	store: Store;
	payment_info_list: PaymentInfo[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((paramMap)=>
			{
				this.print_and_close = paramMap.has('print') && paramMap.get('print') == '1';
				return this.rest.cash_close_info.get( paramMap.get('id') )
			})
			,mergeMap((cash_close_info:CashCloseInfo)=>
			{
				let utc_string = cash_close_info.cash_close.since;
				let d = Utils.getDateFromUTCMysqlString( utc_string );
				console.log('utc_string',utc_string, d);

				let start_date = Utils.getDateFromUTCMysqlString( cash_close_info.cash_close.since );

				let payment_info_search = {
					eq: { created_by_user_id: cash_close_info.cash_close.created_by_user_id, status: 'ACTIVE' as ('ACTIVE' |'DELETED')},
					ge: { created: d },
					le: { created: cash_close_info.cash_close.created },
					limit: 99999999,
					search_extra: {}
				};

				if( this.rest.has_ares )
				{
					payment_info_search.search_extra['ares'] = 1;
				}

				let payment_obs = this.rest.payment_info.search( payment_info_search );

				let bill_data_obs = cash_close_info.store?.default_billing_data_id
					? this.rest.billing_data.get( cash_close_info.store.default_billing_data_id)
					: of( null );

				let returns_search ={
					eq: { cashier_user_id: cash_close_info.cash_close.created_by_user_id },
					ge: { created: d },
					le: { created: cash_close_info.cash_close.created }
				};

				return forkJoin
				({
					cash_close_info: of( cash_close_info ),
					payment_info: payment_obs,
					default_billing_data: bill_data_obs,
					returns: this.rest.returns_info.search( returns_search )
				})
			})
			,mergeMap((response)=>
			{
				this.billing_data = response.default_billing_data;
				this.payment_info_list = response.payment_info.data;

				let total = 0;

				this.payment_info_list.forEach(pi=>
				{
					if( pi.payment.currency_id == 'USD' )
					{
						console.log( pi.payment.payment_amount );
					}
					else
					{
						console.log( pi.payment.exchange_rate );
					}

					total += pi.payment.payment_amount;
				});

				console.log('El total de pagos es '+total+' '+this.payment_info_list.length);

				return of( response.cash_close_info );
			})
		)
		.subscribe((response)=>
		{
			this.current_date = new Date();
			this.cash_close_info = response;
			this.store = response.store;

			this.total_sales	= 0;
			this.total_credit	= 0;
			this.total_discount = 0;

			this.order_counter	= 0;
			this.item_total		= 0;
			this.total_expenses	= 0;

			this.total_income_sales = 0;
			this.total_income_other = 0;
			this.total_order_amount_paid = 0;

			let tmp_payments:Payment[] = [];
			//let currency_rate = this.cash_close_info.currency_rates.find(i=>i.currency_id == 'USD' )

			let currency_rate = this.cash_close_info.currency_rates.find(i=>i.currency_id == 'USD' && i.store_id == this.rest.current_user.store_id )

			if( currency_rate )
				this.currency_rate = currency_rate;

			this.cash_close_info.payments.forEach((payment)=>
			{
				if( payment.type == 'income' )
				{
					if( payment.tag == 'SALE' )
					{
						this.total_income_sales += payment.payment_amount;
					}
					else
					{
						this.total_income_other += payment.payment_amount;
					}
				}
				else
				{
					tmp_payments.push(payment);
				}
			})

			this.expenses_paymens = tmp_payments;

			this.cash_close_info.orders.forEach((order)=>
			{
				let amount_paid = this.getAmountPaidForOrder(this.payment_info_list, order.id);

				this.total_sales				+= order.total;
				this.total_discount				-= order.discount;
				this.total_order_amount_paid	+= amount_paid;
				this.total_credit				+= (order.total-order.discount) - amount_paid;

				console.log('Credito de orden'+order.id+' es '+((order.total-order.discount) - amount_paid));
			});



			this.cash_close_info.item_sales.forEach((x)=>{
				this.item_total += x.total;
			});

			this.total_funds = this.cash_close_info.funds.reduce((prev:number,fund:Fund)=>
			{
				return prev+fund.amount;
			},0);

			this.cash_close_info.movements.forEach((bm:Bank_Movement)=>
			{
				if( bm.type == 'income' )
				{
					let key = this.legend[ bm.transaction_type+'-'+bm.currency_id ];

					if( !(key in this.movements_qtys) )
					{
						this.movements_qtys[key] = { total: 0, currency_id:bm.currency_id, equivalencia: 0 };
					}

					if( this.movements_qtys[key].currency_id == 'USD' )
					{
						let total = bm.total*this.currency_rate.rate;

						this.movements_qtys[key].equivalencia += total;
						this.movements_qtys[key].total+= bm.total;
						this.currencies_total	+= total;
					}
					else
					{
						this.movements_qtys[key].equivalencia += bm.total;
						this.movements_qtys[key].total+= bm.total;
						this.currencies_total	+= bm.total;
					}
				}
				else
				{
					this.total_expenses += bm.total;
				}
			});

			this.cash_close_info = response;

			/*
			if (this.cash_close_info.orders && this.cash_close_info.orders.length > 0)
			{
				let order_ids = this.cash_close_info.orders.map(order => order.id);

				this.returns_info_list = [];
				this.total_returns = 0;

				let orders = order_ids.map(order_id =>
					this.rest.returns_info.search({ search_extra: { order_id } })
				);

				this.subs.sink = forkJoin(orders).subscribe(
					responses =>
					{
						responses.forEach(response =>
						{
							let returns_data: ReturnsInfo[] = response.data || [];
							this.returns_info_list.push(...returns_data);

							this.total_returns += returns_data.reduce((sum, ret) => sum + ret.returns.total, 0);
						});
					},
					error => this.showError('Error obteniendo cancelaciones para las órdenes')
				);
			}
			*/

			if( this.print_and_close)
			{
				this.rest.logout( false );
				setTimeout(()=>{
					console.log('Printing',this.d );
					window.print();
					this.rest.logout(false);
					//window.close();
				},500);
			}
		},(error)=>this.showError(error));
	}

	closeSesion()
	{
		this.rest.logout(false);
		window.removeEventListener('afterprint',this.closeSesion);
	}

	printDirect()
	{
		window.print();
	}

	getAmountPaidForOrder(payment_info_list:PaymentInfo[], order_id:number):number
	{
		let amount = 0;

		for(let payment_info of payment_info_list)
		{
			for(let bm of payment_info.movements)
			{
				for(let bmo of bm.bank_movement_orders)
				{
					if( bmo.order_id == order_id )
					{
						amount += bmo.amount;
					}
				}
			}
		}
		return amount;
	}
}
